const serveSMS = 'https://fcasistemas.com.br/chicos';
const serve    = 'https://fcasistemas.com.br/chicos';
const serveIMG = 'https://fcasistemas.com.br/chicos/up';

export default  class VariaveisGlobais {
    get getHost(){
        return serve;
    }

    get getHostSMS(){
        return serveSMS;
    }

    get getHostIMG(){
        return serveIMG;
    }
}